/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'
import i18n from '@/i18n'

const EN = 'en'

const termService = {
	getTerm(name) {
		return new Promise(async (resolve, reject) => {
			let _url = `/terms/name/${name}`
			if (i18n.locale === EN) {
				_url = `/terms/name/${name}-${EN}`
			}

			try {
				const { data } = await axios({
					method: 'GET',
					url: _url,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default termService
