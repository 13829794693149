<template>
	<div class="mx-4" :class="fromApp ? 'mb-16' : 'my-16'">
		<v-card max-width="1075px" class="mx-auto">
			<v-card-title v-if="showTitle">
				{{ term.title }}
			</v-card-title>
			<v-divider class="mb-3" v-if="showTitle" />
			<v-card-text style="overflow-x: scroll">
				<span v-html="term.body"></span>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import TermService from '@/services/TermService'
import { warningSwal } from '@/plugins/swalMixin'

export default {
	setup() {
		const { route } = useRouter()
		const term = ref({})
		const termType = route.value.params.type
		const fromApp = route.value.query.fromApp
			? JSON.parse(route.value.query.fromApp)
			: false
		const showTitle = route.value.query.showTitle
			? JSON.parse(route.value.query.showTitle)
			: true

		const getTerm = async termType => {
			try {
				term.value = await TermService.getTerm(termType)
			} catch (e) {
				warningSwal('약관을 불러오는데 문제가 발생했습니다')
			}
		}

		watch(
			() => termType,
			currentValue => {
				if (currentValue) {
					getTerm(currentValue)
				}
			},
			{
				immediate: true,
			},
		)

		store.dispatch('app/setIsLogoWhite', false)

		return {
			term,
			fromApp,
			showTitle,
		}
	},
}
</script>
<style lang="scss" scoped></style>
